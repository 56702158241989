<template>
  <div class="">
    <div v-if="returnMessage" class="msg_overlay text-center">
      <h1 :class="classMessage">
        <i
          class="bi bi-bell"
          style="font-size: 103px; display: block"
          :class="classMessage"
        ></i
        ><br />{{ returnMessage }}
      </h1>
    </div>

    <nav class="navbar sticky-top navbar-dark">
      <div class="container-fluid">
        <a class="navbar-brand"
          ><span style="font-size: 1.1rem">Salle 800</span>
          <!-- <h1 style="color: #fff !important">
            {{ total }}
          </h1> -->
        </a>
        <div class="float-end mx-3">
          <button
            type="button"
            class="btn btn-outline-warning"
            id="tools_user"
            @click="toggleMode()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
              ></path>
              <path
                d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
              ></path>
            </svg>
            Changer le scan
          </button>
        </div>
      </div>
    </nav>
    <div
      class="container-fluid d-flex align-items-center justify-content-center"
      style="height: 91vh"
      :class="classMode"
    >
      <div class="row">
        <div class="col-12 text-center">
          <button
            type="button"
            class="btn btn_myicon_success"
            id="readBarcode"
            @click="startScanner"
            v-if="editMode == null"
          >
            <i class="bi bi-upc-scan" style="font-size: 53px"></i>
          </button>
        </div>
        <div class="col-12 text-center mt-3">
          <h1 style="color: #fff !important"></h1>
          <h1 style="color: #fff !important">Lieu : Salle 800</h1>
          <p style="color: #fff !important">
            (Pour démarrer le scanner, cliquez ci-dessous)
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editMode: null,
      openMode: null,
      query: "",
      showModal: false,
      typemodal: null,
      contentmodal: null,
      ModalMessage: null,
      classmodalMessage: null,
    };
  },

  components: {},
  props: [
    "Scans",
    "returnMessage",
    "classMessage",
    "classMode",
    "modeMessage",
    "total",
  ],
  computed: {
    filteruser: function () {
      return this.Scans.filter((s) =>
        s.registered_lastname.toLowerCase().includes(this.query.toLowerCase())
      );
    },
  },
  methods: {
    startScanner() {
      this.$emit("showScanner");
    },

    toggleMode() {
      this.$emit("toggleThemode");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
